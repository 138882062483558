export default {
  production: {
    apiKey: 'AIzaSyAd9FAaPUc2hkK7Xh1ho395Znp_LL1DbGM',
    authDomain: 'giftly-353a9.firebaseapp.com',
    databaseURL: 'https://giftly-353a9.firebaseio.com',
    projectId: 'giftly-353a9',
    storageBucket: 'giftly-353a9.appspot.com',
    messagingSenderId: '744003712149',
    appId: '1:744003712149:web:3d681244dc059a9410979c',
    measurementId: 'G-N7R9PD7X87'
  },
  development: {
    apiKey: 'AIzaSyCvzyl_fm-JQIs7fBdlqnGI6AVgdZl8GMA',
    authDomain: 'giftly-76104.firebaseapp.com',
    databaseURL: 'https://giftly-76104.firebaseio.com',
    projectId: 'giftly-76104',
    storageBucket: 'giftly-76104.appspot.com',
    messagingSenderId: '979657817001',
    appId: '1:979657817001:web:b06d4c11a18d6b0ec222b8'
  }
};