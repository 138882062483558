import { firestore } from '../firebase';
import logger from '../log';

export const getItemById: <T extends { id: string }> (collection: DG.DGCollection) => (id: string) => Promise<T | null> = <T extends { id: string }> (collection: DG.DGCollection) => async (id) => {
  try {
    const entry = await firestore.collection(collection).doc(id).get();

    if (!entry.exists) {
      return null;
    }
    const data = entry.data();

    return {
      id: entry.id,
      ...data,
    } as T;
  } catch (err) {
    logger.error(err);
    return null;
  }
};
