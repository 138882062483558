import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

import logger from './log';
import configs from './firebaseClientConfigs';

// TODO re-enable dev config
const firebaseConfig = configs.production;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const functions = firebase.functions();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export let analytics: firebase.analytics.Analytics;

if (typeof window !== 'undefined') {
  if (window.location.hostname === 'localhost') {
    logger.log('Using firestore/functions/storage/auth emulator');
    firestore.useEmulator('localhost', 8234);
    functions.useEmulator('localhost', 5001);
    storage.useEmulator('localhost', 9199);
    auth.useEmulator('http://localhost:9099');
  }

  import('firebase/analytics').then(() => {
    analytics = firebase.analytics();
  });
}

export default firebase;