import { captureException, captureMessage } from '@sentry/nextjs';

/* eslint-disable no-console */
class Logger {
  private readonly _debug: boolean;

  constructor(debug: boolean) {
    this._debug = debug;
  }

  log(...args: any[]) {
    if (this.debug) {
      console.log(...args);
    }
  }
  warn(...args: any[]) {
    if (this.debug) {
      console.warn(...args);
    }
  }

  error(...args: any[]) {
    if (this.debug) {
      console.error(...args);
    }

    // Always capture error objects
    if (args[0]?.message) {
      captureException(args[0]);
    } else if (!this.debug) {
      // in production report messages
      captureMessage(args.join(', '));
    }
  }

  get debug() {
    return this._debug;
  }
}

export default new Logger(process.env.NODE_ENV !== 'production');
