import { firestore as adminFirestore } from 'firebase-admin';
import firebase, { firestore, functions } from '../firebase';
import logger from '../log';

import { getItemById } from './util';

export const USER_COLLECTION = 'users';
export const PROFILES_COLLECTION = 'profiles';
export const ADMINS_COLLECTION = 'admins';
export const APPROVALS_COLLECTION = 'approvals';

export const getUserById = getItemById<DG.WithId<DG.UserModel>>(USER_COLLECTION);
export const getProfileById = getItemById<DG.WithId<DG.ProfileModel>>(PROFILES_COLLECTION);

export const getIsAdmin = (uid: string) => firestore.collection(ADMINS_COLLECTION).doc(uid).get().then(snapshot => snapshot.exists).catch((err) => {
  logger.error(err);
  return false;
});
  
export const setUser = (id: string, userData: Partial<DG.UserModel>, update = false) => {
  return firestore.collection(USER_COLLECTION).doc(id).set(userData, { merge: update });
}

export const setProfile = (id: string, profileData: Partial<DG.ProfileModel>, update = false) => {
  return firestore.collection(PROFILES_COLLECTION).doc(id).set(profileData, { merge: update });
}

const transformDoc: (doc: firebase.firestore.QueryDocumentSnapshot | adminFirestore.QueryDocumentSnapshot | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => DG.UserModel | null = (doc) => doc.exists ? ({
  uid: doc.id,
  normalizedUsername: doc.data()!.normalizedUsername || doc.data()!.username?.toLowerCase(),
  ...doc.data(),
}) as DG.UserModel : null;

export const getUserByUsername = async (username: string, db: adminFirestore.Firestore | firebase.firestore.Firestore = firestore) => {
  const result = await db.collection(USER_COLLECTION).where('normalizedUsername', '==', username.toLowerCase()).limit(1).get();
  if (result.size === 0) { return null; }
  return transformDoc(result.docs[0]);
};

export const followUsers = (limit: number, onSnapshot: (results: DG.SavedUserModel[], size: number) => void, createdAfter?: firebase.firestore.Timestamp | null, approvedFilter?: 'all' | 'true' | 'false') => {
  let query: firebase.firestore.CollectionReference | firebase.firestore.Query = firestore.collection(USER_COLLECTION);

  if (approvedFilter === 'true' || approvedFilter === 'false') {
    query = query.where('approved', '==', approvedFilter === 'true');
  }

  query = query.orderBy('createdAt', 'desc').limit(limit);
  if (createdAfter) {
    query = query.startAfter(createdAfter);
  }

  return query.onSnapshot((snapshot) => {
    const results = snapshot.docs.map(transformDoc).filter(Boolean) as DG.SavedUserModel[];
    onSnapshot(results, snapshot.size);
  });
}

export const approveUser = (uid: string, username: string, approvedById: string) => {
  return firestore.collection(APPROVALS_COLLECTION).doc(uid).set({ uid, approvedAt: new Date(), approvedById })
    .then(() => setUser(uid, { approved: true }, true))
    .then(() => functions.httpsCallable('sendApprovalEmail')({ approvedId: uid, wishlistUrl: `${location.origin}/${username}` }));
};

export const unapproveUser = (uid: string) => {
  return firestore.collection(APPROVALS_COLLECTION).doc(uid).delete();
};

export const deleteUser = (uid: string, deletingUid: string, banUser?: boolean) => {
  return functions.httpsCallable('selfDeleteUser')({ uid, adminUid: deletingUid, banUser });
}

export const setAdminStatus = (uid: string, isAdmin: boolean, promotedById: string) => {
  if (isAdmin) {
    return firestore.collection(ADMINS_COLLECTION).doc(uid).set({ uid, promotedAt: new Date(), promotedById  })
  }

  return firestore.collection(ADMINS_COLLECTION).doc(uid).delete();
}

export const followUser = (uid: string, onSnapshot: (user: DG.UserModel | null) => void) => {
  return firestore.collection(USER_COLLECTION).doc(uid).onSnapshot((user) => {
    if (!user || !user.exists) {
      onSnapshot(null);
    } else {
      onSnapshot(transformDoc(user));
    }
  })

};

export const followProfile = (uid: string, onSnapshot: (profile: DG.ProfileModel | null) => void) => {
  return firestore.collection(PROFILES_COLLECTION).doc(uid).onSnapshot((profile) => {
    if (!profile?.exists || !profile.data()) {
      onSnapshot(null);
    } else {
      onSnapshot(profile.data() as DG.ProfileModel);
    }
  });
}
